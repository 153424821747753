import sanitizeHtml from "sanitize-html"

export default content => {
  if (typeof content !== "string") {
    return content
  }

  const clean = sanitizeHtml(content, {
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "a",
      "ul",
      "ol",
      "nl",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "strike",
      "code",
      "hr",
      "br",
      "div",
      "table",
      "thead",
      "caption",
      "tbody",
      "tr",
      "th",
      "td",
      "pre",
      "iframe",
      "img",
      "span",
    ],
    disallowedTagsMode: "discard",
    allowedAttributes: {
      a: ["*"],
      div: ["*"],
      img: ["*"],
      iframe: ["*"],
      span: ["*"]
    },
    selfClosing: [
      "img",
      "br",
      "hr",
      "area",
      "base",
      "basefont",
      "input",
      "link",
      "meta",
    ],
    allowedSchemes: ["http", "https", "ftp", "mailto"],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ["href", "src", "cite"],
    allowProtocolRelative: true,
    exclusiveFilter: frame => {
      const elements = ["span", "div"]

      if (elements.includes(frame.tag)) {
        if (frame.attribs.class) {
          return (
            frame.attribs.class.includes("hs-cta-wrapper") ||
            frame.attribs.class.includes("tve-leads-shortcode")
          )
        }

        return frame.text.trim() === ""
      }
    },
  })

  return clean
}
