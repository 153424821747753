import React from "react"
import styled from "styled-components"

// Utils
import handlePrimaryCategory from "../../utils/handlePrimaryCategory"

// Components
import Post from "./card"
import Ad from "../ad/card"

export default ({ posts, category, showAd, adLink, adLabel, adImage }) => {
  return (
    <Wrapper>
      {posts.map(
        ({
          slug,
          id,
          featured_media,
          categories,
          meta,
          title,
          excerpt,
          author,
        }) => {
          return (
            <Post
              link={slug}
              key={id}
              background={
                featured_media && featured_media.localFile && featured_media.localFile.childImageSharp
                ? featured_media.localFile.childImageSharp.fluid
                : false
              }
              category={handlePrimaryCategory(
                category,
                categories,
                meta ? meta.primary_category : false
              )}
              title={title}
              excerpt={excerpt}
              author={author}
            />
          )
        }
      )}
      {showAd && <Ad adLink={adLink} adLabel={adLabel} adImage={adImage} />}
    </Wrapper>
  )
}

// Styled Components
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 2rem;
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
`
