import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const { allFile } = useStaticQuery(graphql`
    query useUnsupportedImages {
      allFile(
        filter: {
          extension: { in: ["svg", "gif"] }
          url: { regex: "/proofstaging.wpengine.com/" }
        }
      ) {
        nodes {
          id
          publicURL
        }
      }
    }
  `)
  return allFile.nodes
}
