import React from "react"
import styled from "styled-components"

// Utils
import { sanitizeTitle } from "../../utils/sanitize"

// Components
import Author from "../../components/post/author"
import Category from "../../components/post/category"
import Background from "../../components/post/background"
import Share from "./share"

export default ({ background, title, url, author, category }) => {
  return (
    <Header>
      <Background image={background} />
      <Title>{sanitizeTitle(title)}</Title>
      <Meta>
        <Share title={title} url={url} />
        <Author
          name={author.name}
          slug={author.slug}
          avatar={
            author.mpp_avatar.full && author.mpp_avatar.full.localFile && author.mpp_avatar.full.localFile.childImageSharp
            ? author.mpp_avatar.full.localFile.childImageSharp.fixed
            : false
          }
        />
        <Category name={category.name} slug={category.slug} plalette={`blue`} />
      </Meta>
    </Header>
  )
}
const Header = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1rem;
  @media (min-width: 992px) {
    grid-row-gap: 2rem;
  }
`
const Title = styled.h1`
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  grid-row: 3;
  @media (min-width: 992px) {
    grid-row: inherit;
  }
`
const Meta = styled.div`
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-row: 2;
  grid-row-gap: 1rem;
  @media (min-width: 992px) {
    display: flex;
    grid-row: inherit;
    align-items: center;
    > :last-child {
      margin-left: 1.5rem;
    }
  }
`
