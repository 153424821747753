import React from "react"
import styled from "styled-components"

export default ({ title, url, sidebar }) => {
  const titleUrl = encodeURI(title.replace(/ /g, "+"))

  const twitterUrl = `http://twitter.com/share?text=${titleUrl}&url=${url}`
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const LinkedInUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${url}/&title=${titleUrl}`

  const isDefined = typeof window !== `undefined`

  return (
    <List sidebar={sidebar}>
      <Item>
        <Button
          href={twitterUrl}
          target={`_blank`}
          onClick={e => {
            e.preventDefault()

            if (isDefined) {
              window.open(twitterUrl, "twitter-share", "width=580,height=296")
              return false
            }
          }}
        >
          <Icon
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Share on Twitter</title>
            <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
          </Icon>
        </Button>
      </Item>
      <Item>
        <Button
          href={facebookUrl}
          target={`_blank`}
          onClick={e => {
            e.preventDefault()

            if (isDefined) {
              window.open(facebookUrl, "facebook-share", "width=580,height=296")
              return false
            }
          }}
        >
          <Icon
            role="img"
            viewBox="0 0 11 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Share on Facebook</title>
            <path d="M11 0H8C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V8H0V12H3V20H7V12H10L11 8H7V5C7 4.73478 7.10536 4.48043 7.29289 4.29289C7.48043 4.10536 7.73478 4 8 4H11V0Z" />
          </Icon>
        </Button>
      </Item>
      <Item>
        <Button
          href={LinkedInUrl}
          target={`_blank`}
          onClick={e => {
            e.preventDefault()

            if (isDefined) {
              window.open(LinkedInUrl, "linkedin-share", "width=490,height=530")
              return false
            }
          }}
        >
          <Icon
            role="img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Share on LinkedIn</title>
            <path d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z" />
            <path d="M6 9H2V21H6V9Z" />
            <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" />
          </Icon>
        </Button>
      </Item>
    </List>
  )
}

// Styled Components
const List = styled.ul`
  ${props =>
    props.sidebar
      ? `
        display:none;
        @media (min-width:992px) {
            display:grid;
            position:sticky;
            top:5rem;
        }
    `
      : `
        display:grid;
        @media (min-width:992px) {
            display:none;
        }
    `}
  grid-template-columns: repeat(3,minmax(0,2rem));
  grid-column-gap: 1.5rem;
  grid-row: 1;
  @media (min-width: 992px) {
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 1rem;
  }
`
const Item = styled.li``
const Button = styled.a`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  padding: 0.45rem;
  border: 2px solid ${props => props.theme.color.neutral["400"]};
  &:hover {
    background-color: ${props => props.theme.color.neutral["400"]};
  }
  @media (min-width: 992px) {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
  }
`
const Icon = styled.svg`
  fill: ${props => props.theme.color.neutral["400"]};
  height: 100%;
  width: 100%;
  ${Button}:hover & {
    fill: #fff;
  }
`
