import { useStaticQuery, graphql } from "gatsby"

export const useAdPlaceholder = () => {
  const { file } = useStaticQuery(graphql`
    query useAdPlaceholderQuery {
      file(sourceInstanceName: { eq: "placeholder" }, name: { eq: "banner_2" }) {
        childImageSharp {
          fluid(maxWidth: 334, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return file.childImageSharp.fluid
}
