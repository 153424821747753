import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Layout Components
import Layout from "../../components/layout/index"
import SEO from "../../components/seo/index"
import Container from "../../components/container"

// Utils
import contentParser from "../../utils/contentParser/index"
import { sanitizeExceprt, sanitizeTitle } from "../../utils/sanitize"
import handlePrimaryCategory from "../../utils/handlePrimaryCategory"

// Components
import Share from "./share"
import Header from "./header"
import Posts from "../../components/post/grid"

export default ({
  data: {
    wordpressPost: {
      excerpt,
      content,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      title,
      featured_media,
      author,
      categories,
      meta,
    },
    allWordpressPost: { nodes: posts },
  },
  location: { href },
  pageContext: { slug },
}) => {
  const category = handlePrimaryCategory(
    false,
    categories,
    meta && meta.primary_category
  )

  const pageTitle = yoast_wpseo_title ? yoast_wpseo_title : sanitizeTitle(title)

  const isFluid = featured_media && featured_media.localFile && featured_media.localFile.childImageSharp && featured_media.localFile.childImageSharp.fluid

  return (
    <Layout template={`post`} pathPrefix={slug} category={category}>
      <SEO
        isBlogPost
        title={pageTitle}
        description={
          yoast_wpseo_metadesc
            ? yoast_wpseo_metadesc
            : `${sanitizeExceprt(excerpt, 158)} [...]`
        }
        slug={slug}
        image={isFluid ? isFluid.src : featured_media && featured_media.localFile && featured_media.localFile.publicURL}
        imageDimensions={isFluid && {
          width: isFluid.presentationWidth,
          height: isFluid.presentationHeight,
        }}
      />
      <Container
        sm={`
          padding-top: 1.5rem;
          grid-row-gap: 2rem;
        `}
        lg={`
          padding-top: 2rem;
          grid-row-gap: 3rem;
        `}
      >
        <Wrapper>
          <Sidebar>
            <Share title={title} url={href} sidebar />
          </Sidebar>
          <Article>
            <Header
              background={isFluid}
              title={title}
              url={href}
              author={author}
              category={category}
            />
            <Content className={`post-content`}>
              {contentParser({
                content,
                slug,
              })}
            </Content>
          </Article>
        </Wrapper>
        {posts.length > 0 && <Posts posts={posts} />}
      </Container>
    </Layout>
  )
}

// Styled Components
const Wrapper = styled.div`
  display: grid;
  @media (min-width: 992px) {
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
    grid-template-columns: 5.75rem minmax(0, 1fr) 5.75rem;
  }
`
const Content = styled.div`
  display: grid;
  grid-row-gap: 2rem;
  grid-template-columns: minmax(0, 1fr);
  @media (min-width: 992px) {
    grid-row-gap: 2.5rem;
  }
`
const Article = styled.article`
  display: grid;
  grid-row-gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
  @media (min-width: 992px) {
    grid-row-gap: 2.5rem;
  }
`
const Sidebar = styled.aside`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`

// Page Query
export const pageQuery = graphql`
  query singlePostQuery($id: String!, $next: [String]!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      excerpt
      slug
      content
      ...postCategory
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 821, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      author {
        name
        slug
        ...authorAvatar
      }
    }
    allWordpressPost(filter: { id: { in: $next } }) {
      nodes {
        id
        title
        excerpt
        slug
        ...postCategory
        featured_media {
          ...postCardBg
        }
        author {
          name
          slug
          ...authorAvatar
        }
      }
    }
  }
`
