import parse from "./parse"
import clean from "./clean"

export default ({ content, slug }) => {
  const cleaned = clean(content)

  const parsed = parse({
    content: cleaned,
    slug,
  })

  return parsed
}
