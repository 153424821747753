/*
    Parameters:
    1: Desired category
    2. Category list
    3. Primary Category
*/

export default (category, categories, primary) => {
  if (category) {
    return category
  }

  const primaryCategory = categories.find(
    ({ wordpress_id }) => wordpress_id === primary
  )

  if (primaryCategory) {
    return primaryCategory
  }

  return categories[0]
}
