import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export default ({
    src,
    title
}) => {
    const [ show, setShow ] = useState(false)

    const wrapper = useRef(null)

    useEffect(() => {
        const domNode = wrapper.current

        let observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setShow(true)
            }
        })

        observer.observe(domNode)

        return () => {
            observer.disconnect()
        }
    }, [ wrapper ])

    return (
        <Wrapper ref={wrapper}>
            {show && <iframe src={src} title={title}/>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    background-color:${props => props.theme.color.neutral['10']};
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
`