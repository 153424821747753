import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// Hooks
import { useAdPlaceholder } from "../../../hooks/placeholder/useAdPlaceholder"

export default ({
  image = useAdPlaceholder(),
  link = `https://www.conversion.ai/?utm_source=useproof.com`,
  label = `AI Powered Marketing Copy and Content`,
}) => {
  return (
    <Wrapper href={link} aria-label={label}>
      <Ad fluid={image} alt={label} />
    </Wrapper>
  )
}

// Styled Components
const Ad = styled(Img)``
const Wrapper = styled.a`
  @media (min-width: 992px) {
    grid-row: 1;
    grid-column: 2;
  }
`
