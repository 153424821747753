import React from "react"
import { renderToStaticMarkup } from "react-dom/server"
import parser, { domToReact } from "html-react-parser"
import styled from "styled-components"
import getByPath from "lodash/get"
import Image from "gatsby-image"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

// Components
import LazyVideo from '../../components/lazy/video'

// Hooks
import useUnsupportedImages from "../../hooks/post/useUnsupportedImages"

export default ({ content, slug }) => {
  const unsupportedImages = useUnsupportedImages()

  if (typeof content !== "string") {
    return content
  }

  const parserOptions = {
    replace: domNode => {
      switch (domNode.name) {
        case `span`:
          // Twitter Caption
          if (
            domNode.attribs &&
            domNode.attribs.class &&
            domNode.attribs.class.includes("bctt-click-to-tweet")
          ) {
            const textNode = domNode.children.find(child =>
              child.attribs.class.includes("bctt-ctt-text")
            ).children[0]
            const linkNode = domNode.children.find(child =>
              child.attribs.class.includes("bctt-ctt-btn")
            ).attribs

            const title = domToReact(textNode.children, parserOptions).trim()
            const link = `https://twitter.com/intent/tweet?url=${process.env.GATSBY_URL}/${slug}&text=${encodeURI(title)}&via=useproof&related=useproof`

            return (
              <Twitter>
                <TwitterWrapper
                  href={link}
                  rel={linkNode.rel}
                  target={linkNode.target}
                  aria-label={`Click to Share Quote on Twitter`}
                >
                  {`"${title}"`}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    fill="none"
                  >
                    <path d="M22 1a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 012 2s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5 0-.279-.028-.556-.08-.83A7.72 7.72 0 0022 1z" />
                  </svg>
                </TwitterWrapper>
              </Twitter>
            )
          }

          // If has random span tags, get rid of them.
          if (domNode.parent) {
            return (
              <React.Fragment>
                {domToReact(domNode.children, parserOptions)}
              </React.Fragment>
            )
          }

          return <span>{domToReact(domNode.children, parserOptions)}</span>
        case `table`:
          return <Table>{domToReact(domNode.children, parserOptions)}</Table>
        case `em`:
          if (!domNode.parent) {
            return (
              <p>
                <em>{domToReact(domNode.children, parserOptions)}</em>
              </p>
            )
          }

          return <em>{domToReact(domNode.children, parserOptions)}</em>
        case `strong`:
          return <strong>{domToReact(domNode.children, parserOptions)}</strong>
        case `div`:
          // If is proof-experience block
          if (
            domNode.attribs.class &&
            domNode.attribs.class.includes(`proofx-anchor`)
          ) {
            return
          }

          // If image has caption, wrap it for responsive styling
          if (
            domNode.attribs.class &&
            domNode.attribs.class.includes(`wp-caption`)
          ) {
            const imageWidth = domNode.children[0].attribs.width

            return (
              <CaptionContainer imageWidth={imageWidth}>
                {domToReact(domNode.children, parserOptions)}
              </CaptionContainer>
            )
          }

          // If image has a quote-box, wrap it for responsive styling
          if (
            domNode.attribs.class &&
            domNode.attribs.class.includes(`quote-box`)
          ) {
            return (
              <QuoteBox>{domToReact(domNode.children, parserOptions)}</QuoteBox>
            )
          }

          // Get rid of those god damn unnecessary divs wrapping all the elements
          return (
            <React.Fragment>
              {domToReact(domNode.children, parserOptions)}
            </React.Fragment>
          )
        case `figure`:
          return (
            <React.Fragment>
              {domToReact(domNode.children, parserOptions)}
            </React.Fragment>
          )
        case `p`:
          // If paragraph tag is wrapping an image/iframe, flag it as an invalid practice
          const isInvalidMedia = domNode.children && domNode.children.some(childNode => {
            return (
              (childNode.attribs &&
                childNode.attribs.class === "gatsby-inline-image-fluid") ||
              (childNode.attribs &&
                childNode.attribs.class ===
                  "gatsby-inline-image-unsupported") ||
              childNode.name === `iframe` ||
              childNode.name === `img`
            )
          })

          if (isInvalidMedia) {
            return (
              <React.Fragment>
                {domToReact(domNode.children, parserOptions)}
              </React.Fragment>
            )
          }

          // If parent is blockquote, get rid of the unnecessary paragraph tag
          if (domNode.parent && domNode.parent.name === `blockquote`) {
            return (
              <React.Fragment>
                {domToReact(domNode.children, parserOptions)}
              </React.Fragment>
            )
          }

          // If is wrapping an image
          const wrappedParent =
            domNode.children &&
            domNode.children.find(({ name }) => name === "a")
          if (wrappedParent) {
            const includesImage =
              wrappedParent.children &&
              renderToStaticMarkup(
                domToReact(wrappedParent.children, parserOptions)
              ).includes(`<img`)

            if (includesImage) {
              return (
                <React.Fragment>
                  {domToReact(domNode.children, parserOptions)}
                </React.Fragment>
              )
            }
          }

          // If paragraph has has only empty spaces, remove it
          if (domNode.children.length === 1) {
            if (domNode.children[0].data && !domNode.children[0].data.trim()) {
              return <React.Fragment />
            }
          }
          // If paragraph is just a blank space, remove it.
          if (domNode.children.length === 0) {
            return <React.Fragment />
          }

          return <p>{domToReact(domNode.children, parserOptions)}</p>
        case `h2`:
          return <h2>{domToReact(domNode.children, parserOptions)}</h2>
        case `h3`:
          return <h3>{domToReact(domNode.children, parserOptions)}</h3>
        case `h4`:
          return <h4>{domToReact(domNode.children, parserOptions)}</h4>
        case `blockquote`:
          // Quote box
          if (
            domNode.parent &&
            domNode.parent.attribs &&
            domNode.parent.attribs.class &&
            domNode.parent.attribs.class.includes("quote-box")
          ) {
            return
          }

          return (
            <Blockquote>
              {domToReact(domNode.children, parserOptions)}
            </Blockquote>
          )
        case `ul`:
          return <Ul>{domToReact(domNode.children, parserOptions)}</Ul>
        case `ol`:
          return <Ol>{domToReact(domNode.children, parserOptions)}</Ol>
        case `li`:
          return <li>{domToReact(domNode.children, parserOptions)}</li>
        case `a`:
          // Replace internal links with gatsby link component
          const url = domNode.attribs && domNode.attribs.href
          
          const localUrl = process.env.GATSBY_URL
          const sourceUrl = process.env.GATSBY_SOURCE_URL

          let targetUrl
          
          if (url && url.includes(localUrl)) {
            targetUrl = url.split(localUrl)[1]
          }

          if (url && url.includes(sourceUrl)) {
            targetUrl = url.split(sourceUrl)[1]
          }

          if (targetUrl) {
            return (
              <A
                as={Link}
                to={`/${targetUrl}`}
                target={domNode.attribs.target}
                rel={domNode.attribs.rel}
                id={domNode.attribs.id}
              >
                {domToReact(domNode.children, parserOptions)}
              </A>
            )
          }

          // If url includes a scroll target
          if (url && url.charAt(0) === `#`) {
            return (
              <A
                onClick={() => scrollTo(domNode.attribs.href)}
                target={domNode.attribs.target}
                rel={domNode.attribs.rel}
                id={domNode.attribs.id}
                aria-label={domNode.attribs["aria-label"]}
              >
                {domToReact(domNode.children, parserOptions)}
              </A>
            )
          }

          // Cleanup External links
          return (
            <A
              href={domNode.attribs.href}
              target={domNode.attribs.target}
              rel={domNode.attribs.rel}
              id={domNode.attribs.id}
              aria-label={domNode.attribs["aria-label"]}
            >
              {domToReact(domNode.children, parserOptions)}
            </A>
          )
        case `iframe`:
            if (domNode.attribs && domNode.attribs.src && domNode.attribs.src.includes('youtube.com')) {
              return (
                <LazyVideo
                  src={domNode.attribs.src}
                  title={domNode.attribs.title}
                />
              )
            }

            return (
              <IframeWrapper>
                <iframe
                  src={domNode.attribs.src}
                  height={`100%`}
                  width={`100%`}
                  title={domNode.attribs.title}
                />
              </IframeWrapper>
            )
        case `img`:
          const processImage =
            domNode.name === "img" &&
            getByPath(domNode, "attribs[image-data]", null)

          const imageData = JSON.parse(processImage)

          if (processImage) {
            if (domNode.attribs.class === "gatsby-inline-image-unsupported") {
              const { publicURL } = unsupportedImages.find(
                ({ id }) => id === imageData
              )

              return (
                <Img
                  src={publicURL}
                  alt={domNode.attribs.alt && domNode.attribs.alt}
                  title={domNode.attribs.title && domNode.attribs.title}
                  maxWidth={
                    domNode.attribs.width > 820
                      ? `820px`
                      : `${domNode.attribs.width}px`
                  }
                />
              )
            } else {
              return (
                <GatsbyImage
                  fluid={imageData}
                  alt={domNode.attribs.alt && domNode.attribs.alt}
                  title={domNode.attribs.title && domNode.attribs.title}
                  style={{
                    maxWidth:
                      domNode.attribs.width > 820
                        ? `820px`
                        : `${domNode.attribs.width}px`,
                  }}
                />
              )
            }
          }

          return (
            <Img
              src={domNode.attribs.src}
              alt={domNode.attribs.alt && domNode.attribs.alt}
              title={domNode.attribs.title && domNode.attribs.title}
            />
          )
        default:
          return
      }
    },
  }

  return parser(content, parserOptions)
}

// Styled Components
const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-color: ${props => props.theme.color.neutral["600"]};
  td,
  th {
    font-size:0.85rem;
    padding: 0.25rem;
    border: 1px solid;
    @media (min-width: 992px) {
      padding: 0.5rem;
      font-size:1rem;
    }
  }
`
const Twitter = styled.span`
  font-family: "GT Walsheim Pro";
  font-size: 2rem;
  line-height: 1.15;
  font-weight: 500;
  text-align: center;
  svg {
    fill: ${props => props.theme.color.neutral["400"]};
    margin: 0 auto;
  }
  &:hover {
    color: ${props => props.theme.color.blue["400"]};
    svg {
      fill: ${props => props.theme.color.blue["400"]};
    }
  }
  @media (min-width: 992px) {
    font-size: 2.25rem;
    line-height: 1.2;
  }
`
const TwitterWrapper = styled.a`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1.375rem;
`
const Img = styled.img`
  display: block;
  margin: 0 auto;
  ${props =>
    props.maxWidth
      ? `
    max-width: ${props.maxWidth}
    width:100%;
    `
      : ``};
`
const GatsbyImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`
const QuoteBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  background-color: ${props => props.theme.color.neutral["15"]};
  .gatsby-image-wrapper {
    height: 100%;
  }
  blockquote {
    padding: 1rem;
    text-align: center;
    font-size: 1.5rem;
    cite {
      display: block;
      color: ${props => props.theme.color.blue["400"]};
      margin-top: 1rem;
    }
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
`
const Blockquote = styled.blockquote`
  border-left: 4px solid #c7d1e4;
  padding-left: 1rem;
  font-size: 1.25rem;
  @media (min-width: 992px) {
    padding-left: 2rem;
    font-size: 1.6875rem;
  }
`
const CaptionContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
  grid-row-gap: 1rem;
  text-align: center;
  max-width: ${props => (props.imageWidth ? `${props.imageWidth}px` : "")};
  width: 100%;
  margin: 0 auto;
  p {
    color: ${props => props.theme.color.neutral["400"]};
    @media (min-width: 992px) {
      font-size: 1.15rem;
    }
  }
`
const A = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.color.blue["400"]};
`
const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`
const Ul = styled.ul`
  font-family: "GT Super Text";
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1rem;
  line-height: 1.5;
  li {
    padding-left: 1rem;
    position: relative;
    :before {
      content: "";
      position: absolute;
      left: 0;
      top: 7.5px;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: ${props => props.theme.color.blue["400"]};
    }
    ul {
      grid-row-gap: 0.5rem;
      margin-top: 0.5rem;
    }
    @media (min-width: 992px) {
      :before {
        top: 10.5px;
      }
      padding-left: 1.5rem;
      font-size: 1.125rem;
    }
  }
`
const Ol = styled.ol`
  font-family: "GT Super Text";
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1rem;
  line-height: 1.5;
  counter-reset: custom-ol-counter;
  list-style: none;
  li {
    counter-increment: custom-ol-counter;
    position: relative;
    :before {
      content: counter(custom-ol-counter) ". ";
      font-weight: bold;
      color: ${props => props.theme.color.blue["400"]};
    }
    ul {
      grid-row-gap: 0.5rem;
      margin-top: 0.5rem;
    }
    @media (min-width: 992px) {
      font-size: 1.125rem;
    }
  }
`
