import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Components
import Background from "./background"
import Category from "./category"
import Title from "./title"
import Excerpt from "./excerpt"
import Author from "./author"

export default ({ background, category, title, excerpt, author, link }) => {
  return (
    <Article>
      <Content>
        <Background image={background} />
        <Category name={category.name} slug={category.slug} />
        <Title content={title} />
        <Excerpt content={excerpt} />
        <PostLink to={`/${link}`} aria-label={`Read more about ${title}`} />
      </Content>
      <Author
        name={author.name}
        slug={author.slug}
        avatar={
          author.mpp_avatar.full && author.mpp_avatar.full.localFile && author.mpp_avatar.full.localFile.childImageSharp
          ? author.mpp_avatar.full.localFile.childImageSharp.fixed
          : false
        }
      />
    </Article>
  )
}

// Styled Components
const Article = styled.article`
  height: 100%;
  display: grid;
  grid-row-gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
  @media (min-width: 992px) {
    grid-row-gap: 1.25rem;
  }
`
const Content = styled.div`
  position: relative;
  display: grid;
  align-content: start;
  grid-row-gap: 1rem;
  grid-template-columns: minmax(0, 1fr);
  @media (min-width: 992px) {
    grid-row-gap: 1.25rem;
  }
`
const PostLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
